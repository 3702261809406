

body {
  font-family: "Lato", Helvetica, Arial, sans-serif !important;
  font-size: 0.875rem;

}
* {
   scroll-behavior: 'thin';
    font-family: "Lato", Helvetica, Arial, sans-serif !important;
}
a {
  text-decoration: none;
  color: #000000;
}
.clearBoth {
  clear: both;
}
.App {
  
}
.PinturaButtonExport{
  display: none;
}

.progressBar{
  width: 95%;
  height: 15px;
  background-color: #000;
  border: 0px;
}
.topBar {
  align-items: center;
 color: white;
  list-style: none;
  display: flex;
  width:100%;
  background-color: #394E6D;
  height:50px;
  border-bottom: 1px solid #CCCCCC;
  /*box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) */
}
.topBarBoxLogo {
  width: 20%;
}
.topBarBoxIcon{
 width: 20%;  
}
.topBarBoxSearch{
  width: 60%;
}
.nav-links li {
  margin-left: 10px;
}

.sideBarWrapper {
  float: left;
  width: 250px;
  background-color: #394E6D;
  
  /*box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14) */
}
.sideBarContent {
  overflow-y: auto;
  overflow-x: hidden;
}
.mainWrapper {
    width: calc(100% - 250px);
    float: left;
    
}

/*******Sidebar Profile*******/
.userProfileSection {
  padding: 9px 0px 16px 5px;
  height: 25px;
  background-color: #27364D;
}

.userName {
  color: #FFF;
line-height: 42px;
float: left;
padding-left: 6px;
cursor: pointer;
}
.userProfileLink {
float: left;
line-height: 55px;
color: #FFFFFF;
}
/*******Sidebar Profile*******/

/*************************/

.channelListingWrapper{
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width:thin;
}

.channelList {
  margin-left: -5px !important;
}

.channelList .MuiCollapse-wrapper {
  background-color: #324661;
}

.channelList li {
  list-style: none;
  padding: 10px 0px;
 
  padding-left: 20px;
  margin-right: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.channel-active {
  background-color: rgba(255, 255, 255);
  color: #000000 !important;
}

.channelList .channelName {
  display: block;
  width: 90%;
border-top-right-radius: 24px;
border-bottom-right-radius: 24px;
 color: #FFFFFF;
 padding: 6px;
 
padding-left: 16px;
}
.channelList .channelName:hover{
  background-color: rgba(255, 255, 255);
  color:  #000000;

}

.deleteChannel{
  display: block;
  width: 94%;
  margin-left: 16px;
font-size: 17px;
color: #FFF;
height: 34px;
padding-top: 10px;
background-color: #FFFFFF1A;
font-weight: bold;

} 

.deleteChannel:hover {
background-color: #FFFFFF;
color: #000;

}
.trash-channel-active {
background-color: #FFFFFF;
color: #000;
}


.dropzone {
    flex: 1;
    border: 1px dashed #666;
    display: flex;
    outline: none;
    transition: border .24s ease-in-out;
    align-items: center;
    border-radius: 2px;
    flex-direction: column;
    font-weight: bold;
    cursor: pointer;
    background-color: #D2D3E83D;
    padding: 22px 0px 16px 0px;
    width: 99% !important;
}
.dropzone:hover {
    background-color: #D2D3E83D;
    transition: background-color .35s ease-in-out;
    }

.bradecrumbbox {
  width: 100%;
  flexGrow: 1
}

.breadcrumbSubHeading {
}


::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
  opacity: 0.5
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

.MuiButton-contained:hover{
  background-color:#371B58 !important
}

.uploadmediatext {
  float: right;
line-height: 39px;
font-size: 22px;
margin-left: 8px;
}
#menuIconBurger{
  display: none;
}
.invitememberbutton {
 background-color: #394E6D  !important;
 color: #FFFFFF  !important;
}
.invitememberfield {
  width: 450px  !important;
}


.cardParentDiv{
  width: 24% !important;
  float:left;
  margin-right: 10px;
  margin-top: 5px;

 
}
.cardHeader {
  position: absolute;
  padding-top: 5px !important;
  padding-left: 7px !important;
  display: none !important;
}
.cardHeaderUnsplash {
  position: absolute;
  padding-top: 5px !important;
  padding-left: 7px !important;
}
.cardHeaderUnsplash {
  position: absolute;
  padding-top: 5px !important;
  padding-left: 7px !important;
  width: 95%;
}


.cardHeader .MuiCardHeader-content {
  margin-left: -10px;
}
.headerTitle {
  color: #FFF;
  font-weight: bold !important;
line-height: 13px !important;
}
.headerDate {
  color: #FFF;
  font-size: 11px !important;
}
.imageMainCard{
  border: 1px solid #CCC;
border-radius: 5px !important;
box-shadow: none;
position: relative;
box-shadow: none !important;
}
.imageMainCardUnsplash{
 border: 0px solid #CCC;
border-radius: 5px !important;
box-shadow: none;
position: relative;
box-shadow: none !important;
}


.imageMainCard:hover .cardHeader{
  display: flex !important;
  
}
.cardImage {
  opacity: 1;
  transition: .5s ease

}
.imageMainCard:hover .cardImage {
 opacity: 0.1;
}

.imageActions{
  height: 40px;
  padding: 0px !important;
  background-color: #F5F4F9;
  margin-top: -4px;
}

.imgAvatar{
  width: 40px !important;
  height: 40px !important;
  text-transform: uppercase !important;
  background-color: #FF5001 !important;
}

.filterTuner{
   display:none;
    position: absolute;
    z-index: 999;
right: 0;
width: 200px;
background-color: #33344a;
height: 250px;
color: #FFF;
border: 1px solid #333131;
border-radius: 5px 0px 0px 5px;
padding: 5px;
}

.teamnamepriv{
 float: left;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
width: 185px;
font-size: 19px;
text-transform: uppercase;
font-weight: bold;
margin-left: 10px;
letter-spacing: 1px;
 
}
.teamnamenopriv{
 float: left;
margin-top: -6px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
width: 220px;
 
}


.verifyTextBox {
  width: 11%;
border: 1px solid #C6C6C6;
height: 70px;
outline: none;
font-size: 40px;
text-align: center;
margin-left: 4px;
border-radius: 4px;
}

.addAppButton{
  width: 100%;
display: inline-block;
border: 1px solid rgba(0, 0, 0, 0.23);
line-height: 29px;
border-radius: 5px;
text-align: center;
font-size: 15px;
}
.addAppButton:hover{
  background: rgba(0,0,0,0.04);
  cursor: pointer;
}

.browseapps {
    color: #464775;
    font-size: 15px;
}
.browseapps:hover{
  text-decoration: underline;
}
.browseappicon {
    float: left;
  width: 17px !important;
  margin-top: -2px;
}
.addtoteambutton {
  background-color: #464775 !important;
width: 85%;
color: #FFF !important;
}
/*****UNSPLASH APP CSS*****/
.searchBar{
  height: 38px;
  border: 1px solid #ECEAEA;
  border-radius: 20px;
  background-color: #F7F7F7;
}
.searchField {
  width: 97%;
margin-left: 15px;
height: 33px;
margin-top: 1px;
border: none;
background: transparent;
outline: none;
font-size: 14px;
font-weight: normal;
color: #242222;
}
.searchIcon{
 height: 30px !important;
width: 30px !important;
cursor: pointer;
}
.cardParentUnsplash {
   width: 24.2% !important;
  float: left;
  margin-left: 4px;
  margin-top: 7px;
  margin-right: 3px;
}

.cardHeaderTitle {
    font-size: 14px !important;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    text-overflow: ellipsis;
}

.notphotofoundmsg{
  text-align: center;
  margin-top: 150px !important;
}

.photoPaging {
  width: 50%;
margin: 0px auto;
margin-top: 3px;
}

.searchKeywords {
  margin-right: 3px !important;
  color: #464775 !important;
  border: 1px solid #464775 !important;
}

.pagingGrid {
 border-top: 1px solid #CCC;
margin-top: 2px !important;
}


/******Editor CSS******/
#grid_previewpan{
    border:0px solid red;
    width:100%;
    position:fixed;
    bottom:0;
    min-height:355px;
    background-color:#F8F8F8;
    z-index:40;
    bottom:-530px;
}
.preview_close{
    padding:5px;
    padding-bottom:15px;
}
.preview_close img{
    cursor:pointer;
}
#grid_previewpan h1{
    font-size:26px !important;
    margin:0px !important;
    font-weight:bold !important;
    color:#FFF !important;
}
#grid_previewpan #preview_description{
    font-size:16px;
    margin:5px 0px !important;
    font-weight:normal !important;
    color:#aaaaaa !important;
}
#grid_previewpan .preview_meta{
    font-size:14px;
    margin:5px 0px !important;
    font-weight:normal !important;
    color:#aaaaaa !important;
}
#grid_previewpan button,
#crop-image-popup button{
    border-radius:30px;
}
#grid_previewpan .search,#grid_previewpan .upload, #crop-image-popup .search, #crop-image-popup .upload{
    width: 70px;
    background-color: #039;
    height: 25px;
    font-size: 10px;
    line-height: 8px;
    padding: 0px !important;
    border-radius:6px;
}
#grid_previewpan .download, #crop-image-popup .download{
    background-color: #F03;
    height: 32px;
    font-size: 16px;
    line-height: 8px;
    padding: 0px 20px !important;
    color:#FFF;
}
#grid_previewpan .crop_nav, #crop-image-popup .crop_nav{
    width: 80px;
    background-color: #FFF;
    color: #333;
    height: 25px;
    font-size: 10px;
    line-height: 8px;
    padding: 0px !important;
    border-radius: 6px;
    border: 1px solid #333;
    margin-top: 6px;
}
#grid_previewpan .fourthird, #crop-image-popup .fourthird{
    border: 1px solid #000;
    width: 32px;
    height: 24px;
    font-size: 11px;
    text-align: center;
    line-height: 24px;
    margin-top: 7px;
}
#grid_previewpan .thirdfourth, #crop-image-popup .thirdfourth{
    border: 1px solid #000;
    width: 23px;
    height: 30px;
    font-size: 11px;
    text-align: center;
    line-height: 30px;
    margin-top: 5px;
}
#grid_previewpan .sixteenninth, #crop-image-popup .sixteenninth{
    border: 1px solid #000;
    width: 42px;
    height: 24px;
    font-size: 11px;
    text-align: center;
    line-height: 24px;
    margin-top: 7px;
}
#grid_previewpan .ninesixteenth, #crop-image-popup .ninesixteenth{
    border: 1px solid #000;
    width: 24px;
    height: 36px;
    font-size: 11px;
    text-align: center;
    line-height: 36px;
    margin-top: 1px;
}
#grid_previewpan .twooneth, #crop-image-popup .twooneth{
    border: 1px solid #000;
    width: 48px;
    height: 24px;
    font-size: 11px;
    text-align: center;
    line-height: 24px;
    margin-top:7px;
}
#grid_previewpan .onetwoth, #crop-image-popup .onetwoth{
    border: 1px solid #000;
    width: 20px;
    height: 40px;
    font-size: 11px;
    text-align: center;
    line-height: 40px;
}
#grid_previewpan .mid_nav, #crop-image-popup .mid_nav{
    width: 70px;
    background-color: #3CC;
    height: 25px;
    font-size: 10px;
    line-height: 8px;
    padding: 0px !important;
    border-radius:6px;
}
.floatleft{
    float:left;
}
.floatright{
    float:right;
}
.clearboth{
    clear:both;
}
.grid_nav{
    border-top:1px solid #999;
    padding:4px 0px;
    color:#FFF;
}
.grid_nav div {
    margin-right: 1px;
}
#crop_section{
    padding:3px 0px;
    border-top:0px solid #CCC;
}
#gif_section, #text_section{
    padding:3px 0px;
    border-top:0px solid #CCC;
    display:none;
}
#facebook_crop, #twitter_crop, #pinterest_crop, #googlead_crop{
    padding: 0px 0px 4px 0px;
    border-bottom: 2px solid #000;
    display:none;

}
#facebook_crop div {
    margin-left: 1px;
}


.download{color:#FFF;}

.limit_reach_msg{color:red; }
.remaining_download_msg{color:#FFFFFF; font-size:14px;}

.mfp-content{ margin-bottom:70px;}

.facebook_crop_ad{ 
    cursor:pointer;
    font-size: 11px;
    text-align: center;
    border: 1px solid #FFF;
    padding: 2px 6px;
    color: #FFF;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bolder;
}
a.facebook_crop_ad:hover{
color: #fff;
background-color: #1D2027;
} 

.text_image_area {
  max-width: 100%;
  width: max-content;
 /* float: left;*/
}

@media screen and (max-width: 2000px) {
  #preview_img_src, #orignal_img_src{
    max-height: 66vh;
    max-width: 100%;
   
  }
}

@media screen and (max-width: 1400px) {
  #preview_img_src, #orignal_img_src{
    max-height: 66vh;
    max-width: 100%;
   
  }
}
@media screen and (max-width: 769px) {
 .topBarBoxLogo {
  width: 45%;
  } 
  .cardParentUnsplash {
    width: 31% !important;
  }
  .unsplashImage{
    height: 115px !important;
  }
  .cardHeaderUnsplash .MuiCardHeader-content, .cardHeaderUnsplash .MuiCardHeader-avatar{
    display: none !important;
  }
  .desktop-only{
    display: none !important;
  }
}



#preview_img_src img{
    width:97%;
    height:auto;
}

#original_preview_box {
  /*float: left;*/
width: 97%;
position: relative;
padding-top: 10px;
}


.preview_main_area {
    min-height: 485px;
    /*float:left;*/
    width:100%;
    padding-left:6px;
}
.download{color:#FFF;}

.limit_reach_msg{color:red;}

.preview_crop_options{ float:left; width:98%;}


#crop_this_image{ }
.download_button {
    margin-top:20px;
}
a.white.button.is-outline {

color: #FFF !important;
border:2px solid #FFF;
}

.downloaded_loading {
background-color: #FF0033;
width: 142px;
border-radius: 23px;
text-align: center;
height: 42px;
line-height: 42px;
display:none;
}

.click:active{
    text-decoration:none;

}
.click:hover{
    text-decoration:none;
}
.red{background-color:red !important;}
.orange{background-color:orange !important;}
.yellow{background-color:yellow !important;}
.green{background-color:green !important;}
.blue{background-color:blue !important;}
.brown{background-color:brown !important;}
.purple{background-color:purple !important;}
.grey{background-color:grey !important;}
.white{background-color:white !important; color: #666 !important;}
.black{background-color:black !important;}
.no-image-found{color:#FFF; display:none;}

.button, input[type="submit"], input[type="reset"], input[type="button"] {
    position: relative;
    color: #fff;
    display: inline-block;
    background-color: transparent;
    text-transform: uppercase;
    font-size: .97em;
    letter-spacing: .03em;
    -ms-touch-action: none;
    touch-action: none;
    cursor: pointer;
    font-weight: bolder;
    text-align: center;
    color: currentColor;
    text-decoration: none;
    border: 1px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    vertical-align: middle;
    border-radius: 0;
    margin-top: 0;
    
    text-shadow: none;
    line-height: 2.4em;
    min-height: 2.5em;
    padding: 0 1.2em;
    max-width: 100%;
    transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
}

.slide-pane {
  background: #313247 !important;
  height: 95vh !important;
  margin-top: 5vh !important;
}

.crop_button, .gif_button {
    border-radius: 5px;
    background-color: #5b6ddf;
}

.box-shadow-4-hover:hover, .row-box-shadow-4-hover .col-inner:hover {
    -ms-transform: translateY(-6px);
    transform: translateY(-6px);
}
#fm_preview_right {
    color: #FFF;
    line-height: 25px;
    padding-left: 10px;
}

#preview_title {
    line-height: 22px;
    text-transform: capitalize;
    font-size: 20px !important;
margin: 0px !important;
font-weight: bold !important;
color: #FFF !important;
}

.social-button{
  width: 23%;
  margin: 0px;
  padding: 0px;
}

.custom-button{
width: 12%;
margin-right: 3px;
display: inline-block;
font-size: 14px;
 }
 .downloadcrop{
  float:left;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #D26E4C;
 }
 .facebook_ratio{
  width: 140px;
display: inline-block;
font-size: 11px;
background-color: transparent;
margin-top: 3px;
 }
 .twitter_ratio{
width: 22% !important;
display: inline-block;
font-size: 11px;
background-color: transparent;
margin-top: 3px;
 }
 .pinterest_ratio{
  width: 22% !important;
display: inline-block;
font-size: 11px;
background-color: transparent;
margin-top: 3px;
 }
 .google_ratio{
  width: 120px !important;
display: inline-block;
font-size: 11px;
background-color: transparent;
margin-top: 3px;
 }
.layer_button{
  width: 19%;
}
.downloadgif {
  border-radius: 4px;
}

#preview_type {
  text-transform: uppercase;
}

.feature-button{
  width: 17%;
}



#draggable { background-color:transparent; z-index: 1;  }
    #draggable span { background-color:transparent;}
  .remove_button {border: 1px solid #666262;
    height: 13px;
    position: absolute;
    margin-top: -25px;
    margin-left: -5px;
    background-color: #FFFFFF87;
    font-size: 12px;
    text-align: center;
    color: #514f4f;
    border-radius: 11px;
    padding: 2px 7px 4px 7px;
    cursor: pointer;
    left: 47%;
    font-family: Arial;
  }


  .fm-toolbar{
  width:64px;
  height:100%;
  float:left;
  background-color:#444570;
  padding-top:0px;
  margin-top: 0px;
}

.editorRightPane{
  background-color:#333449;
}

.fm-tool-btns{
  padding:10px 0px 10px 0px;
  cursor: pointer;
}
.fm-tool-btns:hover, .fm-tool-btns:active, .fm-tool-btns.active{
  padding:10px 0px 10px 0px;
  background-color: #333449;
}

.fm-tool-btns a{
  display:block;
}

.fm-sidenav.open {
  width: 250px;
  height:100%;
  
}

.fm-sidenav {
  height:-webkit-fill-available;
  width: 0;
  position:absolute;
  z-index: 601;
 /* bottom: 0;*/
  left: 65px;
  background-color: #333449;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.5s;
  margin-left: -1px;


}

.preview_close{
  padding:17px !important;
  padding-bottom:15px;
}
.preview_close img{
  cursor:pointer;
}

.fm-center {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.slide-pane__content {
  padding: 0px !important;
  background-color: #191925;
}

.fm-textedit-box{
  overflow: auto;
  clear: both;
  height: 100%;
}
.fm-textedit-box .text-field {
  margin-top: 10px;
  text-align: left;
}
.fm-textedit-box .text-field label {
  font-size: 15px;
  color: #FFF;/*
  float: left;*/
  margin-bottom: 0px;
  display: inline-block;
  width: 85px;
}
.savetextbutton {
   background-color:#d26e4b !important; 
   margin-top:10px !important; 
   border-radius:5px !important; 
   text-transform:uppercase !important; 
   padding: 0px !important;
   color: #FFF !important;
   font-size: 16px !important;
   width: 97%;
}
.fm-textedit-box .text-field input   {

  background-color: #000;
  border: none;
  height: 18px;
  color: #FFF;
  box-shadow: none;
  width: 215px;
  font-size: 15px;
}
.fm-textedit-box .text-field select {
 background-color: #000;
border: none;
height: 32px;
color: #FFF;
box-shadow: none;
width: 228px;
font-size: 15px;
}

.fm-textedit-box .text-field option {
  background-color: #27292C;
}
.fm-textedit-box .text-field input:hover {
  box-shadow: none;
}

.fm-textedit-box img{
  padding:10px 0px;
  opacity:.3;
  cursor:pointer;
}
.fm-textedit-box img:hover{
  padding:10px 0px;
  opacity:1;
    transition: 0.8s;
}

.fm-sidenav a:hover {
  color: #f1f1f1;
}

.fm-sidenav .fm-closebtn {
  /*position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;*/

    font-size: 36px;
  margin-left: 50px;
  float: right;
  clear: both;
  margin-right: 5px;
  line-height: 0px;
  margin-top: 15px;
}

.fm-info-box, .fm-imgupload-box, .fm-textedit-box{
  padding:0px 10px 0px 10px;
  clear: both;
  /*margin-top:40px;*/
  color: #FFF;
}

.preview_meta {
    margin-top: 20px;
    font-size: 15px;
}

.resolution_size_area {
  background-color: #000;
padding: 6px;
border-radius: 3px;
}

.fm-uploadimg {
  border: 1px dashed #ffffff;
  color: #ffffff;
  background-color: #18191e;
  width:200px;
  height:200px;
  border-radius:3px !important;
  font-size: 16px;
  font-weight: bold;
  line-height: 180px;
}

.fm-imgupload-box input[type=file] {
  /*font-size: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;*/
}



label[for="file"]{
  display: inline-block;
  background-color: transparent;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}




.fm-sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;

}

.fm-sidenav a:hover {
  color: #f1f1f1;
}
.downloadtext {
 text-decoration: none !important;
font-size: 16px !important;
color: #FFF !important;
display: block !important;
transition: 0.3s !important;
border-radius: 3px !important;
height: 22px !important;
  background-color: #D26E4C !important;
margin-top: 20px !important;
padding:0px !important;
}

#grid_previewpan h1{
  font-size:26px !important;
  margin:0px !important;
  font-weight:bold !important;
  color:#FFF !important;
}
#grid_previewpan #preview_description{
  font-size:16px;
  margin:5px 0px !important;
  font-weight:normal !important;
  color:#aaaaaa !important;
}
#grid_previewpan .preview_meta{
  font-size:14px;
  margin:5px 0px !important;
  font-weight:normal !important;
  color:#aaaaaa !important;
}


.NunitoFont {  font-family: 'Nunito', sans-serif !important; font-size: 20px; }
.RobotoFont {  font-family: 'Roboto', sans-serif !important; font-size: 20px; }
.LobsterFont {  font-family: 'Lobster', cursive !important; font-size: 20px; }
.YellowTailFont {  font-family: 'Yellowtail', cursive !important; font-size: 20px; }
.BebasNeueFont {  font-family: 'Bebas Neue', cursive !important; font-size: 20px; }
.AmitaFont {  font-family: 'Amita', cursive !important; font-size: 20px; }
.GreatVibesFont {  font-family: 'Great Vibes', cursive  !important; font-size: 20px; }
.PacificoFont {  font-family: 'Pacifico', cursive  !important; font-size: 20px; }
.PermanentMarkerFont {  font-family: 'Permanent Marker', cursive  !important; font-size: 20px; }

.fm-btn{
  width:90%;
  height:60px;
  border-radius:8px;
  margin-top:10px;
}

.fm-btn a{
  color:#FFF;
  font-size:16px;
  font-weight:800;
  text-decoration:none;
  display:block;
  line-height:58px;
  border-radius:8px;
}


.fm-btn a:hover{
  border:1px solid #373944;
  color:#FFF;
  font-size:16px;
  font-weight:800;
  text-decoration:none;
  display:block;
  /*line-height:100px;*/
  border-radius:8px;
}

.fm-crystal{
  background-image:url(http://files.stocky.ai/uploads/2020/12/crystal.gif);
  background-position:center center;
}

.fm-hearts{
  background-image:url(http://files.stocky.ai/uploads/2020/12/hearts.gif);
  background-position:center center;
}

.fm-snow{
  background-image:url(http://files.stocky.ai/uploads/2020/12/snow.gif);
  background-position:center center;
}

.fm-sparkling{
  background-image:url(http://files.stocky.ai/uploads/2020/12/sparkling.gif);
  background-position:center center;
}

.fm-twinkle{
  background-image:url(http://files.stocky.ai/uploads/2020/12/twinkle.gif);
  background-position:center center;
}

.fm-download{
  background-color:#d26e4b !important;
}

.textCopyToButton {
  text-decoration: none !important;
  font-size: 16px !important;
  color: #111111d6 !important;
  display: block !important;
  transition: 0.3s !important;
  border-radius: 3px !important;
  background-color: #FFD843 !important;
  margin-top: 10px !important;
  padding: 0px !important;
  line-height: 32px; 
  width: 225px;
}
#channelListForCopy, #channelListForCropped, #channelListForGif, #channelListForWatermark{
  text-align: left;
  position: absolute;
  background-color: #FFD843;
  width: 300px;
  margin-top: -3px;
  display: none;
  max-height: 200px;
  overflow:auto;

}
.toggleChannelList {
      list-style: none;
    margin-left: -40px;
    line-height: 24px;
    font-size: 15px;
    margin-top: 0px;

}
.toggleChannelList li{
 padding: 5px 0px 5px 10px;
}
.toggleChannelList li:hover{
  background-color: #EEE !important;
  color: #000;
  cursor: pointer;
}
.folderSection {
  float: left;
  margin-left: 8px;
  height: 19px;
  border-radius: 15px;
  padding: 1px 10px;
  line-height: 18px;
  margin-top: 4px;
  background-color: #2e2f43;
  color: #FFF;
}

.medianotify {
  position: absolute;
  display: flex;
  top: 34px;
  right: 20px;
  z-index: 1;
  border: 0px solid rgb(153, 219, 153);
  width: 450px;
  background-color: rgba(14, 255, 119, 0.9) !important;
  color: #040100 !important;
}



/* The container */
.container {
  display: block;
position: relative;
padding-left: 23px;
margin-bottom: 4px;
cursor: pointer;
font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #FFF;
  border-radius: 2px;
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #FFF;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #3f51b5;
  border: 0px !important;
  height: 18px;
  width: 19px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#gif_preview_src {
 mix-blend-mode:screen; 
}

.meter::after {
    background: linear-gradient( 90deg, rgba(0,0,0,0) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 21%, rgba(0,0,0,0) 21%, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 40%, rgba(0,0,0,1) 41%, rgba(0,0,0,0) 41%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 61%, rgba(0,0,0,0) 61%, rgba(0,0,0,0) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 81%, rgba(0,0,0,0) 81% );
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .meter {
    background-color: #FFFFFF;
    flex-grow: 1;
    position: relative;
  }
  .bar {
    height: 15px;
  }

   .filter_section_disabled{
    pointer-events:none;
    opacity: 0.4;
  }

  .unchecked-checkbox {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #848491;
  width: 15px;
  height: 15px;
  border-radius: 3px; /* Set the border styles for unchecked state */
  /* Add any other styles to visually indicate an unchecked state */
}