@media only screen and (max-width: 1023px) {
  .sideBarWrapper {
    display:none;
    position: absolute;
    z-index: 999;
  }
  .mainWrapper {
    width: 100%
  }
  #menuIconBurger{
  display: flex;
  }
  .dropzone {
    width: 150px
  }
  .uploadmediatext {
    font-size: 14px;
  }
  .invitememberbutton {
    font-size: 10px !important;
  }
  .teammemberheading, .invitememberheading  {
    font-size: 22px !important;
  }
  .invitememberfield {
    width: 100%  !important;
  }
  .cardParentDiv{

    width: 47% !important;
  }
  .cardHeader{
    padding: 3px !important;
  }
  .imgAvatar{
    width: 25px !important;
    height: 25px !important;
  text-transform: uppercase;
  background-color: #b71c1c;
  font-size: 12px;
  }
  .breadcrumbMainHeading {
font-size: 12px !important;
}
.breadcrumbSubHeading{
font-size: 12px !important;
}

.verifyTextBox {

  width: 15%;
border: 1px solid #C6C6C6;
height: 55px;
outline: none;
font-size: 20px;
text-align: center;
margin-left: 4px;
border-radius: 4px;
}
}
