body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI','Normal', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@font-face {
	font-family: 'Yellowtail';
	src: url(./fonts/Yellowtail-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Lobster';
	src: url(./fonts/Lobster-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto';
	src: url(./fonts/Roboto-Regular.ttf) format('truetype');
}

 @font-face {
	font-family: 'Nunito';
	src: url(./fonts/Nunito-Regular.ttf) format('truetype');
}
 @font-face {
	font-family: 'Reey';
	src: url(./fonts/Reey-Regular.ttf) format('truetype');
}*/

/*
    font-family: 'Bebas Neue', cursive;

    font-family: 'Dancing Script', cursive;

    font-family: 'Great Vibes', cursive;

    font-family: 'Lobster', cursive;

    font-family: 'Nunito', sans-serif;

    font-family: 'Pacifico', cursive;

    font-family: 'Permanent Marker', cursive;

    font-family: 'Roboto', sans-serif;

    font-family: 'Yellowtail', cursive;
    */

@import url('https://fonts.googleapis.com/css2?family=Amita&family=Bebas+Neue&family=Great+Vibes&family=Lobster&family=Nunito:wght@200&family=Pacifico&family=Permanent+Marker&family=Roboto:wght@600&family=Yellowtail&display=swap');